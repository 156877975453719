import React, { useState } from 'react';
import { Box, Button, CircularProgress, Stack} from '@mui/material';
import { CssTextField } from './textField';
import { InitiateTrasaction, AddToWallet } from '../service/paytmState';
import { CheckoutProvider, Checkout } from 'paytm-blink-checkout-react'

export const Paytm = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const PhoneNumber = queryParams.get("number")
    const Token = queryParams.get("token");
    const [amount, setAmount] = useState("");
    const [showCheckout, setShowCheckout] = useState(false);
    const [orderId, setOrderId] = useState("");
    const [token, setToken] = useState("");
    const [loading, setLoading] = useState(false);

    const paytm = async () => {
        setLoading(true);
        const rqestedObj = {
            amount: amount,
            mobileNumber: PhoneNumber,
        }
        const res = await InitiateTrasaction(rqestedObj, Token);
        if (res.responseObj.responseDataParams.data.data.body.resultInfo.resultMsg === "Success") {
            const result = res.responseObj.responseDataParams.data;
            setToken(result.data.body.txnToken);
            setOrderId(result.orderId);
            setShowCheckout(true);
        }
    }

    const CONFIG = {
        "root": "",
        "style": {
            "bodyBackgroundColor": "#fafafb",
            "bodyColor": "",
            "themeBackgroundColor": "#0FB8C9",
            "themeColor": "#ffffff",
            "headerBackgroundColor": "#284055",
            "headerColor": "#ffffff",
            "errorColor": "",
            "successColor": "",
            "card": {
                "padding": "",
                "backgroundColor": ""
            }
        },
        "data": {
            "orderId": orderId,
            "token": token,
            "tokenType": "TXN_TOKEN",
            "amount": amount /* update amount */
        },
        "payMode": {
            "order": ["UPI"],
            "filter": {
                "exclude": []
            },
        },
        "website": "WEBSTAGING",
        "flow": "DEFAULT",
        "merchant": {
            "mid": "QAGTnM88758376455736",
            "redirect": false
        },
        "handler": {
            "transactionStatus": function transactionStatus(paymentStatus) {
                const req = {
                    orderId: orderId
                }
                if (paymentStatus.STATUS === "TXN_SUCCESS") {
                    setShowCheckout(false);

                    AddToWallet(req, Token).then((res) => {
                        if (res.responseObj.responseDataParams.data.body.resultInfo.resultStatus === "TXN_SUCCESS") {
                            setLoading(false);
                            window.close();
                        }
                        else {
                            setLoading(false);
                            setShowCheckout(false);
                            window.close();
                        }
                    })
                }
                else {
                    setLoading(false);
                    setShowCheckout(false);
                    AddToWallet(req, Token).then((res) => {
                        window.close();
                    })
                }
            },
            "notifyMerchant": function notifyMerchant(eventName, data) {
                setLoading(false);
            },
        }
    };

    const USE_EXISTING_CHECKOUT_INSTANCE = 'Use existing checkout instance : ';
    const getCheckoutJsObj = () => {
        if (showCheckout) {
            if (window && window.Paytm && window.Paytm.CheckoutJS) {
                window.Paytm.CheckoutJS.init(CONFIG).then(function onSuccess() {
                    window.Paytm.CheckoutJS.invoke();
                })
                return window.Paytm.CheckoutJS.invoke();
            }
            else {
                console.log(USE_EXISTING_CHECKOUT_INSTANCE + 'Checkout instance not found!');
            }
        }
        else {
            return null
        }

    }
    const checkoutJsInstance = getCheckoutJsObj();

    return (
        <>
            {

                showCheckout === true ?
                    <CheckoutProvider env="PROD" config={CONFIG} checkoutJsInstance={checkoutJsInstance}>
                        <Checkout />
                    </CheckoutProvider> : (
                        <>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <CssTextField type="number" label="Amount" id="custom-css-outlined-input" value={amount}
                                    onChange={(e) => { setAmount(e.target.value) }} />
                            </Box>
                            <Button variant="contained" style={{ backgroundColor: "#ED1C24", fontWeight: 200, fontSize: 15 }} onClick={paytm}>Add Funds</Button>
                        </>
                    )

            }


            {loading ? <>  <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
                <CircularProgress color="error" />
            </Stack></> : null
            }


        </>

    )
}
