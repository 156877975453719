import { apiUrl, fetchPayment, validVpa, processTransaction, transactionStatus, apiWallet } from "./Config";
import axios from "axios";

export const InitiateTrasaction = async (body, token) => {
    try {
        const response = await axios.post(
            `${apiUrl}api/v1/wallet/initiateToken`,
            body,
            {
                headers: {
                    token: token,
                },
            }
        );
        return response.data
    } catch (error) {
        return error
    }

}

export const FetchPayment = async (body) => {
    return await axios.post(`${apiUrl}${fetchPayment}`, body)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            return error
        })
}

export const ValidVpa = async (body) => {
    return await axios.post(`${apiUrl}${validVpa}`, body)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            return error
        })
}

export const ProcessTransaction = async (body) => {
    return await axios.post(`${apiUrl}${processTransaction}`, body)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            return error
        })
}

export const TransactionStatus = async (body) => {
    return await axios.post(`${apiUrl}${transactionStatus}`, body)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            return error
        })
}

export const AddToWallet = async (body, token) => {
    
    return await axios.post(`${apiUrl}${apiWallet}`, body,             {
        headers: {
            token: token,
        },
    })
        .then((res) => {
            return res.data
        })
        .catch((error) => {
            return error
        })
}


export const WalletStatus = async (orderId) => {
    const obj = {
        orderId: orderId,
    };
    try {
        const response = await axios.post(
            `${apiUrl}/api/v1/wallet/statusChecking`,
            obj,
        );
        return response;
    } catch (error) {
        return error;
    }
};