import { Paytm } from './Paytm/paytmfile';


function App() {
  return (
      <div style={{textAlign :"center"}}>
        <div style={{display : "flex", flexDirection : "column", alignItems : "center", justifyContent : "center", height : "100vh"}}>
          <Paytm />
        </div>
      </div>
  );
}

export default App;
